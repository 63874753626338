import { PageProps } from "gatsby"
import React, { memo, useEffect, useState } from "react"

interface AccueilPageProps extends PageProps {}

const AccueilPage: React.FC<AccueilPageProps> = props => {
  const bannerImg = [
    { id: 1, img: "/images/banner/ciel_1.jpg" },
    { id: 2, img: "/images/banner/ciel_2.jpg" },
    { id: 3, img: "/images/banner/ciel_3.jpg" },
    { id: 4, img: "/images/banner/ciel_4.jpg" },
    { id: 5, img: "/images/banner/ciel_5.jpg" },
    { id: 6, img: "/images/banner/ciel_6.jpg" },
    { id: 7, img: "/images/banner/ciel_7.jpg" },
    { id: 8, img: "/images/banner/ciel_8.jpg" },
    { id: 9, img: "/images/banner/ciel_9.jpg" },
    { id: 10, img: "/images/banner/ciel_10.jpg" },
    { id: 11, img: "/images/banner/ciel_11.jpg" },
    { id: 12, img: "/images/banner/ciel_12.jpg" },
    { id: 13, img: "/images/banner/ciel_13.jpg" },
  ]

  const [img, setImg] = useState<string>(bannerImg[0].img)

  useEffect(() => {
    checkTime()
  }, [])

  const checkTime = () => {
    const hours = new Date().getHours()
    const minutes = new Date().getMinutes()
    const isEvenHours = hours % 2 === 0
    const isHalfHour = minutes > 30

    if (isEvenHours && isHalfHour) {
      setImg(bannerImg[1].img)
    } else if (isEvenHours && !isHalfHour) {
      setImg(bannerImg[2].img)
    } else if (!isEvenHours && isHalfHour) {
      setImg(bannerImg[3].img)
    } else if (!isEvenHours && isHalfHour) {
      setImg(bannerImg[4].img)
    } else if (!isEvenHours && isHalfHour) {
      setImg(bannerImg[5].img)
    } else if (!isEvenHours && isHalfHour) {
      setImg(bannerImg[6].img)
    } else if (!isEvenHours && isHalfHour) {
      setImg(bannerImg[7].img)
    } else if (!isEvenHours && isHalfHour) {
      setImg(bannerImg[8].img)
    } else if (!isEvenHours && isHalfHour) {
      setImg(bannerImg[9].img)
    }else if (!isEvenHours && isHalfHour) {
      setImg(bannerImg[10].img)
    }else if (!isEvenHours && isHalfHour) {
      setImg(bannerImg[11].img)
    }else if (!isEvenHours && isHalfHour) {
      setImg(bannerImg[12].img)
    } else {
      setImg(bannerImg[0].img)
    }
  }

  // const checkTime = () => {
  //   const hours = new Date().getHours()
  //   const minutes = new Date().getMinutes()
  //   let halfHour = 0
  //   if ((minutes / 60) * 100 > 50) {
  //     halfHour = 1
  //   }
  //   const count = hours * 2 + halfHour
  //   const remain = ("" + (count % 4)).split(".")[1]

  //   switch (remain) {
  //     case "25":
  //       setImg(bannerImg[1].img)
  //       break
  //     case "5":
  //       setImg(bannerImg[2].img)
  //       break
  //     case "75":
  //       setImg(bannerImg[3].img)
  //       break
  //     default:
  //       setImg(bannerImg[0].img)
  //       break
  //   }
  // }

  return (
    <div
      id="changeImage"
      className="w-screen h-screen bg-center bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${img})` }}
    />
  )
}

AccueilPage.displayName = "Pages/Accueil"

export default memo(AccueilPage)
